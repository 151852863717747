<script>
/* eslint-disable vue/no-v-html */
import { mapGetters } from 'vuex';
import { isEmpty } from '@/utils/lodash';
export default {
  computed: {
    ...mapGetters('options', ['option']),
    note() {
      return this.option(
        'virtual_conferences_options_attendee_my_schedule_top_note'
      );
    },
    hasNote() {
      return !isEmpty(this.note);
    }
  }
};
</script>

<template>
  <div
    v-if="hasNote"
    class="my-4"
  >
    <a-alert type="warning">
      <span v-html="note" />
    </a-alert>
  </div>
</template>