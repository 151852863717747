<template>
  <div class="space-y-4">
    <schedule-top-note />
    <a-heading>My Schedule</a-heading>

    <div class="flex flex-col lg:flex-row-reverse">
      <div class="w-full lg:w-4/12 lg:px-4">
        <filters
          show-export
          :items="filterItems"
          :active="activeType"
          @change="onFilterChange"
        />
      </div>
      <div class="w-full lg:w-8/12 space-y-4">
        <dates-tabs
          v-model="selectedDate"
          :dates="scheduleDates"
          :selected="selectedDate"
          @change="onDateChange"
          @input="onDateModelChange"
        />
        <div
          v-if="hasRows"
          class="space-y-4"
        >
          <meeting-card
            v-for="row in rows"
            :key="row.id"
            :meeting="row"
            schedule
          />
        </div>
        <div v-else-if="scheduleLoaded">
          <a-alert type="info">
            There are no meetings to display
          </a-alert>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { filter, isEmpty } from '@/utils/lodash';
import { mapGetters, mapState } from 'vuex';
import MeetingCard from '@/components/meetings/card/card.vue';
import DatesTabs from '@/components/ui/dates/dates-tabs.vue';
import Filters from '@/components/schedules/filters/type.vue';
import ScheduleTopNote from '@/components/schedules/my-schedule/top-note.vue';
import { format } from '@/utils/dates';
export default {
  components: {
    Filters,
    DatesTabs,
    MeetingCard,
    ScheduleTopNote
  },
  data: () => ({
    selectedDate: '',
    selectedType: null
  }),
  computed: {
    ...mapState('user', ['schedule']),
    ...mapGetters('user', [
      'scheduleLoaded',
      'scheduleDates',
      'total',
      'countByType'
    ]),
    rows() {
      let rows = this.schedule;

      if (!isEmpty(this.selectedDate)) {
        rows = filter(
          rows,
          row => format(row.slot.start, 'YYYY-MM-DD') === this.selectedDate
        );
      }

      if (!isEmpty(this.selectedType)) {
        rows = filter(rows, row => row.type.key === this.selectedType);
      }

      return rows;
    },
    hasRows() {
      return !isEmpty(this.rows);
    },
    filterItems() {
      let items = [
        {
          key: 'all',
          label: 'All',
          icon: 'home',
          badge: this.total,
          visible: true
        },
        {
          key: 'seminar',
          label: 'Seminar',
          icon: 'book-open',
          badge: this.countByType('seminar'),
          visible: true
        },
        {
          key: 'meeting',
          label: '1:1 Meetings',
          icon: 'handshake',
          badge: this.countByType('meeting'),
          visible: this.countByType('meeting') > 0
        },
        {
          key: 'presentation',
          label: 'Presentation',
          icon: 'user-chart',
          badge: this.countByType('presentation'),
          visible: true
        },
        {
          key: 'event',
          label: 'Event',
          icon: 'calendar-week',
          badge: this.countByType('event'),
          visible: this.countByType('event') > 0
        },
        {
          key: 'stream',
          label: 'Stream',
          icon: 'signal-stream',
          badge: this.countByType('stream'),
          visible: this.countByType('stream') > 0
        }
      ];

      return filter(items, item => item.visible);
    },
    activeType() {
      return !isEmpty(this.selectedType) ? this.selectedType : 'all';
    }
  },
  methods: {
    onDateChange(date) {
      if (this.selectedDate === date) {
        this.selectedDate = '';
      } else {
        this.selectedDate = date;
      }
    },
    onDateModelChange(event) {
      this.selectedDate = event.target.value;
    },
    onFilterChange(item) {
      if (item.key === 'all') {
        this.selectedType = null;
      } else {
        this.selectedType = item.key;
      }
    }
  }
};
</script>